import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { Textarea, ImageUploader } from '../../components/formComponents'
import moment from 'moment'
import t from '../../service/translate.service'
import findIndex from 'lodash/findIndex'
import pdfSvg from '../../assets/svg/pdf.svg'
import PropTypes from 'prop-types'
import resizer from 'react-image-file-resizer'
import { Alert } from 'antd'

const RepairsAndInquiriesContent = ({
  addFile,
  files,
  item,
  amendment,
  submitAmendment,
  onAmendInput,
  toggleAmendForm,
  showAmendForm,
  showSuccessMessage,
  validators,
  statuses,
  srAmendmentImagesEnabled,
  downloadDocument,
  error
}) => {
  if (!item) return <div />
  return (
    <div className='content'>
      <div>
        <div className='contract-details'>
          <p>{item.contract.contractNumber}</p>
          <p>{item.contract.address}</p>
        </div>
      </div>
      <div>
        <div className='custom-progress'>
          {statuses.map((status, index) => {
            const currentStatusIndex = findIndex(statuses, item.repairAndInquiryStatus)
            const statusClass = index < currentStatusIndex ? '' : index === currentStatusIndex ? status.name.toLowerCase().replace(' ', '-') : 'disabled'
            const statusDate = status.id === 2 ? item.inProgressAt : status.id === 3 ? item.resolvedAt : item.created_at
            return (
              <div key={index} className={`item ${statusClass}`}>
                <div className='container'>
                  <div className='step'>{status.id}</div>
                  <div className='label'>{localStorage.getItem('locale') === 'en' ? status.name : status.nameDe}</div>
                  <div className='date'>{statusDate && moment(statusDate).format('D.M.Y')}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        <div className='details'>
          <div className='label'>
            <p>{t.YOUR_INQUIRY}</p>
            {item.urgent && (
              <div className='urgent'>{item.urgent ? 'URGENT' : ''}</div>
            )}
          </div>
          <p className='label'>{t.DAMAGE_REPORT_SUBJECT}</p>
          <p>{item.subject}</p>
          <p className='label'>{t.DAMAGE_REPORT_DESCRIPTION}</p>
          <p>{item.description}</p>
        </div>
      </div>
      {!!item.damageReportAttachments.length && (<RenderAttachment attachments={item.damageReportAttachments} downloadDocument={downloadDocument} />)}
      <div>
        {item.feedback && (<div>
          <div className='feedbackContainer'>
            <p className='feedback'>{t.FEEDBACK_TITLE}</p>
            <p className='feedbackText'>{item.feedback}</p>
          </div>
        </div>)
        }
        {showAmendForm ? (
          <AmendForm
            amendment={amendment}
            addFile={addFile}
            files={files}
            item={item}
            submitAmendment={submitAmendment}
            srAmendmentImagesEnabled={srAmendmentImagesEnabled}
            onAmendInput={onAmendInput}
            toggleAmendForm={toggleAmendForm}
            error={error}
            validators={validators} />
        ) : (
          <div>
            <div className='amended'>
              {showSuccessMessage && (<div className='notification confirmation'>
                <div className='content'>
                  <div className='message'>
                    {t.AMEND_SUCCESS}
                  </div>
                </div>
              </div>)}
              {item.amendments.map(amendment => {
                return (
                  <div key={amendment.id}>
                    <div className='label'>
                      {t.AMENDED_ON + ' '} {moment(amendment.updated_at).format('D.M.Y')}
                    </div>
                    <p>{amendment.amendment}</p>
                  </div>
                )
              })
              }
            </div>
            <div className='text-center'>
              {item.id && item.repairAndInquiryStatus.id != 3 && (
                <div
                  className='button tertiary'
                  onClick={e => toggleAmendForm()}
                >
                  {t.AMEND}
                </div>)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const RenderAttachment = ({ attachments, downloadDocument }) => {
  return (
    <div>
      <div className='upload-preview'>
        {attachments.map((file, index) => {
          return (
            <div key={index} className='item'>
              <div className='container'>
                <div className='box'>
                  <div className='image'>
                    {file.originalName.includes('.pdf') ? (
                      <a onClick={() => downloadDocument(file?.name)}>
                        <img style={{ maxWidth: '100%', padding: '12px' }} src={pdfSvg} />
                      </a>
                    ) : (
                      <img src={'/uploads/' + file.name} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
RenderAttachment.propTypes = {
  attachments: PropTypes.object
}

const AmendForm = ({ addFile, files, item, submitAmendment, onAmendInput, toggleAmendForm, validators, srAmendmentImagesEnabled, error, amendment }) => {
  const resizeFile = (file) =>
    new Promise((resolve) => {
      resizer.imageFileResizer(file, 1600, 1600, 'jpeg', 90, 0,
        (uri) => {
          resolve(uri)
        },
        'file'
      )
    })
  const onImageDrop = async (files) => {
    const resizedImg = files.map(async (file) => {

      if (file.type.includes('image/')) {
        const image = await resizeFile(file)
        image.preview = file.preview
        return image
      }

      return file
    })
    const resized = await Promise.all(resizedImg)
    addFile(resized)
  }
  return (
    <div>
      {error && <Alert message={error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      <Form onSubmit={e => submitAmendment(e, item.id)}>
          <Textarea
            label={t.AMEND_LABEL}
            placeholder={t.AMEND_PLACEHOLDER}
            value={amendment}
            onChange={e => onAmendInput(e.currentTarget.value)}
            validate={validators.message}
          />
        {srAmendmentImagesEnabled && (
            <ImageUploader label={t['DAMAGE_REPORT_UPLOAD_IMAGE']} files={files} onImageDrop={onImageDrop} />
        )}
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button type='submit' className='button primary'>
              {t.AMEND}
            </Button>
            <div
              className='button secondary'
              onClick={e => toggleAmendForm()}
            >
              {t.AMEND_CANCEL}
            </div>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}
AmendForm.propTypes = {
  addFile: PropTypes.func,
  files: PropTypes.array,
  item: PropTypes.object,
  amendment: PropTypes.string,
  submitAmendment: PropTypes.func,
  onAmendInput: PropTypes.func,
  toggleAmendForm: PropTypes.func,
  validators: PropTypes.object,
  srAmendmentImagesEnabled: PropTypes.bool
}
RepairsAndInquiriesContent.propTypes = {
  addFile: PropTypes.func,
  files: PropTypes.array,
  item: PropTypes.object,
  amendment: PropTypes.string,
  submitAmendment: PropTypes.func,
  onAmendInput: PropTypes.func,
  toggleAmendForm: PropTypes.func,
  error: PropTypes.bool,
  showAmendForm: PropTypes.bool,
  showSuccessMessage: PropTypes.bool,
  statuses: PropTypes.object,
  validators: PropTypes.object,
  srAmendmentImagesEnabled: PropTypes.bool
}

export default RepairsAndInquiriesContent
