import { action, extendObservable } from 'mobx'
import { isMobile, isAndroid, isIOS } from '../service/deviceDetection.service'

class MobileRedirectPopUp {
  constructor() {
    extendObservable(this, {
      isOpen: isMobile,
      link: ''
    })
    this.setLink()
  }
  setIsOpen = action(() => {
    this.isOpen = false
  })
  setLink() {
    if (isAndroid) {
      this.link = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.gcpapp'
    } else if (isIOS) {
      this.link = 'https://apps.apple.com/us/app/gcp-service-app/id1190303260'
    }
  }
}

export default MobileRedirectPopUp
