import React from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import './mobileRedirectPopUp.css'

const MobileRedirectPopUp = (props) => {
  return (
    <div style={{ display: `${props.mobileRedirectPopUpStore.isOpen ? 'flex' : 'none'}` }} className='mobile-redirect-popup-holder'>
      <button className='close-popup-btn' type='button' onClick={() => props.mobileRedirectPopUpStore.setIsOpen()}>
        ×
      </button>
      <div className='mobile-redirect-popup-logo'>
        <img src='https://www.grandcityproperty.de/mieter-app/gcp-app-logo.png' alt='logo' />
      </div>
      <p className='mobile-redirect-popup-text'>{t.REDIRECT_TO_MOBILE}</p>
      <a className='mobile-redirect-link' href={`${props.mobileRedirectPopUpStore.link}`}>In der App</a>
    </div>
  )
}

MobileRedirectPopUp.propTypes = {
  mobileRedirectPopUpStore: PropTypes.object
}

export default inject('mobileRedirectPopUpStore')(observer(MobileRedirectPopUp))
